<template>
    <h3 class="header">{{label}}</h3>
</template>

<script>
export default {
    props: {
        label: String,
    },
    name: 'HeaderMain',
}
</script>
