<script setup></script>

<template>
  <div class="body-container">
    <div id="main-content">
      <div style="width: 100%">
        <div id="form-container">
          <h2>How can we help you today?</h2>
          <p style="margin-bottom: 15px">
            We want to make it easy for you to use our site. But, if you have
            questions, you may be able to find the answers here.
          </p>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  How to choose people to nominate
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px; height: 0px; transition-duration: 200ms"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  How to choose people to nominate if you have left your
                  organization
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  Can I use this site in my own language?
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  How many people should I nominate?
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  What is a coach?
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  Why grant coach access to my report?
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div
              class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters css-67l5gl"
            >
              <div
                class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters brand-primary-color brand-secondary-background-color css-1v8goyf"
                tabindex="0"
                role="button"
                aria-expanded="false"
              >
                <div
                  class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters css-17o5nyn"
                >
                  Which browsers are supported by this website?
                </div>
                <div class="MuiAccordionSummary-expandIconWrapper css-1fx8m19">
                  <svg role="img" style="width: 20px; height: 20px">
                    <use
                      href="/images/icons/expand_more.svg#id"
                      width="20px"
                      height="20px"
                    ></use>
                  </svg>
                </div>
              </div>
              <div
                class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden css-a0y2e3"
                style="min-height: 0px"
              >
                <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                  <div
                    class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb"
                  >
                    <div role="region" class="MuiAccordion-region">
                      <div class="MuiAccordionDetails-root css-u7qq7e">
                        <div style="margin-top: 20px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            If you need more information or are experiencing technical
            difficulties, please contact us:
          </p>
          <ul>
            <li>By email:</li>
          </ul>
          <p>We’d like to help you sort things out.</p>
          <button
            class="global-button-primary brand-primary-background-color global-button"
            type="button"
            @click="goToLogin"
          >
            <div style="display: flex; justify-content: center; height: auto">
              Login
            </div>
          </button>
        </div>
      </div>
      <noscript
        >You need a browser with JavaScript enabled to use this website.</noscript
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpPageComponent",
  methods: {
    goToLogin() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped></style>
