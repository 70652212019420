<script setup>
</script>
<template>
<div class="choose-screen">
  <h1>TalentSage LLC Privacy Policy</h1>
<h1>Last Updated: 2022 September 15</h1>
<p>At TalentSage LLC (“TalentSage”, “we,” “us,” or “our,”) we are committed to protecting the privacy of all who use our site, Https://TalentSage.com, (Our Site), any subdomains of Our Site and any of our Services (Services). To this end, we have designed Our Site with the General Data Protection Regulation (EU Regulation 2016/679) (“GDPR”) in mind.</p>
<p>This document explains our Privacy &amp; Cookies Policies (Privacy Policy). It summarizes the information we gather, how we use that information and who has access to that information. It also describes your rights in relation to that information, how long we keep it and who you can contact for more information or complaints.</p>
<p>Please carefully read this policy carefully and contact us with any questions. We accept your first use of Our Site as acceptance of our Privacy Policy.</p>
<p>In short, we only collect and use the minimum Personal Data we believe to be required to perform Our Services and the research we conduct. We use the minimum number of cookies we believe to be necessary to deliver a secure, personalized service. We collect and process Personal Data and use Cookies in ways that are described in our policies and consistent with our obligations and your rights under the law.</p>
<p>Although you do not have to provide all the personal information requested, if we ask and you refuse, we may be limited in our ability to provide all the information or Services you are seeking from us.</p>
<p>Note: For the purpose of the General Data Protection Regulation (GDPR) – Regulation (EU) 2016/679, and any further regulations such as Schrems II and the invalidation of the EU-US Privacy Shield, TalentSage is a data processor (Company Number: 4343380) as is any contracted consultant operating on TalentSage’s behalf.  Our Data Protection Officer is Stuart Pembery.</p>
<h1>What does this policy cover?</h1>
<p>This Privacy Policy applies to each User’s access and use of TalentSage’s range of Services, Products or Technology platforms (including websites, mobile applications, APIs, content, surveys, courses, tools, coaching) (collectively the “Services”). This policy covers the use and collective acts associated with collecting, handling, rendering, protecting and storing your personal data (Processing) Personal Data to deliver these Services. Some Services will require Users to register for an Account. Each Account contains records, data and credentials used to access and/or use certain areas and features of Our Site.</p>
<p>Personal data and information (“Personal Data”) is “any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier.” In short, Personal Data is any information about you that enables you to be identified.</p>
<p>Personal Data under GDPR can refer to any of the following information such as a User’s full name, email / postal addresses, mobile and telephone numbers, identity documents (e.g. passports, drivers licenses), identity numbers (e.g. National Insurance/Social Security, bank accounts), career and educational documents (e.g. qualifications, CVs, recruiting and employment information), electronic location data and other online identifiers.</p>
<p>Additionally, due to the nature of our Services, we will also ask for and collect diagnostic survey responses, coaching support, assignments and/or other learning and development related content.</p>
<p>Our Site may contain links to other websites at times. In principle, when selecting service partners we only contract suppliers or organizations who are GDPR compliant, who are headquartered and governed by governments who establish reciprocal laws and agreements in line with GDPR (such as Privacy Shield) or who have contractually committed to their ability and willingness to be bound by our GDPR policies. However, please note that we have no control over how your Personal Data is collected, stored, or used by other organizations, services or websites. As such, we advise you to check the privacy policies of any such website before providing any data to them.</p>
<h1>Information and Data Collected</h1>
<p>Depending upon your use of Our Site, we may collect some, or all, of the following personal and non-personal data:</p>
<ol>
<li>User’s full names</li>
<li>Contact information including email address and mobile phone number</li>
<li>Employer and title</li>
<li>Demographic information such as nationality, age, gender (special information)</li>
<li>Other information relevant to leadership development surveys</li>
<li>Business services interests</li>
<li>Other information when you report a problem on our site</li>
<li>A record of the correspondence if you contact us</li>
<li>Details of visits to our sites and transactions through fulfilment of your orders</li>
<li>Information provided by completing forms or questionnaires including, without limitation, 360 surveys, personality profiles, skill development profiles and coach notes as well as individual, group and organizational level data</li>
<li>Work completed before, during or after workshops or other Services which could include but not be limited to examples of personal correspondence, videos, images, drawings, or other miscellaneous personal data for development purposes.</li>
<li>Information if, and when, you enter a competition or promotion sponsored by us</li>
</ol>
<p>Note: We understand the importance of protecting children’s privacy. We never knowingly collect private information about individuals under the age of 18.</p>
<h1>Using Your Personal Data</h1>
<p>Under GDPR, we must always have a lawful basis for using Personal Data. This may be because the data is necessary for our performance of a contract with you, because you have consented to our use of your Personal Data, or because it is in our legitimate business interests to use it. Under these conditions, your Personal Data may be used for the following purposes:</p>
<ol>
<li>Providing and managing your Account and your access to Our Site</li>
<li>Entering a Contract and supplying our Services to you</li>
<li>Personalizing and tailoring your experience on Our Site and our Services</li>
<li>Notifying you of changes and additions to Our Services that may affect your Account</li>
<li>Communicating with you, including responding to emails or calls from you</li>
<li>Supplying you with information by email that you have opted-in to and can unsubscribe / opt-out at any time</li>
<li>Analyzing your use of Our Site and Services and gathering feedback to enable us to continually improve Our Site performance and your experience</li>
<li>Producing development reports over time for your personal completion of both nominated and self-initiated development activities</li>
<li>Contacting you (around the scheduled expiry of your Personal Data) for your decision on your desire to anonymize or personally extend Our Services and available options for continuing access to your Personal Data and Account</li>
<li>Creating anonymized, normative databases to provide you with context for your professional development results and for research purposes</li>
</ol>
<p>We never sell your Personal Data nor will we send you any unlawful marketing or spam. We do not use your personal information for automated decision making or profiling.</p>
<p>We will always work to fully protect your rights and comply with our obligations under GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003. We will always give you the opportunity to opt-out of marketing communications.</p>
<h1>Cookies Policy</h1>
<p>Cookies are small text files placed on your computer or device by a site when you visit certain parts of and/or use certain features of a website. Cookies allow web applications to respond to you as an individual and tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
<p>We use the minimum number of Cookies we believe necessary to deliver a secure, personalized service.</p>
<p>In limited circumstances, we may have to use third-party Cookies to facilitate and improve your experience of Our Site and to provide and improve our Services. All Cookies used on Our Site are used in accordance with current Cookie Law (relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003).</p>
<ol>
<li><strong>Strictly necessary</strong>: Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”.</li>
<li><strong>Not strictly necessary</strong>: Some cookies are not required for the functioning of Our Site and are “not strictly necessary”.</li>
</ol>
<p>We will not ask for your consent for “strictly necessary” or before placing any “not strictly necessary” Cookies on your computer or device. But, we consider your use of Our Site as acceptance of our Cookie Policy. If you do not accept our Cookie Policy, you must stop use of Our Site immediately.</p>
<p>By consenting, you are enabling us to provide the best possible experience and Services to you. However, you are not required to consent. Please be aware that if you wish to deny consent, certain features of Our Site may not function fully or as intended, including but not limited to login and personalization.</p>
<p>The following chart indicates any first- or third-party, “strictly necessary” or “not strictly necessary” Cookies in current use that may be placed on your computer or device:</p>
<table width="601">
<tbody>
<tr>
<td width="166">
<p><strong>Name of Cookie</strong></p>
</td>
<td width="115">
<p><strong>Purpose</strong></p>
</td>
<td width="129">
<p><strong>Type/Provider</strong></p>
</td>
<td width="191">
<p><strong>Strictly Necessary?</strong></p>
</td>
</tr>
<tr>
<td width="166">
<p>N/A</p>
</td>
<td width="115">
<p>N/A</p>
</td>
<td width="129">
<p>N/A</p>
</td>
<td width="191">
<p>N/A</p>
</td>
</tr>
</tbody>
</table>
<h1>Storing, Transferring or Sharing Personal Data</h1>
<p>Information you or others provide on your behalf for delivering the Services is stored on our secure servers located in the UK. We encrypt all passwords and authorizations stored for your protection.</p>
<p>We retain your Personal Data for three (3) calendar years from the point your Services were initiated for or by you, unless otherwise instructed by you. At the three (3) year point, we will write to you to ask if you wish to continue your Services with us.</p>
<p>If you agree the terms of this continuation, we will maintain your account and Personal Data under these new terms. If you do not agree to the terms for extending your Services, we will either anonymize, delete or destroy your relevant Personal Data, depending on the type of data expiring. We hold your Personal Data for this length of time to best serve you, should you request further Services from us.</p>
<p>If you have completed any of our surveys, regardless of your preferences, we will also anonymize and retain your survey responses at the three (3) year mark to empirically validate and further enhance the basis of our ongoing research and normative databases.</p>
<p>We limit and control all other forms of data transfer or data sharing as follows:</p>
<ol>
<li><strong>Data transfer for processing/research</strong>- Occasionally, our Services may require we transfer Personal Data for processing or for research studies. In these instances, we will only transfer your Personal Data for processing to countries deemed to provide an adequate level of personal data protection. Examples include the US/EU and US/Switzerland Privacy Shield or others who have agreed contractually to be bound by our privacy policies. If a data transferral case arises, we will maintain a data register to ensure your GDPR rights and only work with specific organizations and individuals who are also trained in our tailored GDPR-compliant research and data protection process. We anonymized data for transfer wherever possible. In cases where we cannot (e.g. some research), we add extra layers of security to protect Personal Data.</li>
<li><strong>Transferring data to Users</strong>- We’ve taken your data privacy one step further when it comes to sharing your reports with others. You are in control of your Personal Data and reports. We’ve designed Our Site, Our Services and Our Business Processes to reduce the transferral of Personal Data, reports and the number of others involved in processing your data to a minimum.</li>
</ol>
<p>If there is any educational or development benefit to sharing your data, we will ask you for your permission to share with your development advisor. We only transfer your data to you (wherever you sign in) or to a limited set of authorized development advisor(s) (wherever they sign in), if you have personally granted report viewing rights to them individually for the relevant purpose(s) and period of your development program.</p>
<ol start="3">
<li><strong>Sharing data with third-parties </strong>- We will not share any of your Personal Data with any other (non-data processing/research, non-User) third parties for any purposes, except for legal requirements. In some limited circumstances, we may be legally required to share certain Personal Data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</li>
</ol>
<p>Even data shared for research purposes is anonymized and governed by our partner research institution’s IRB policies and ethical research guidelines and practices.</p>
<p>If you have any questions about the particular data protection mechanism used by us when transferring your personal data to another country, please contact us using the details in the Contacting TalentSage LLC section of this policy.</p>
<h1>Your Data Rights</h1>
<p>We will always work to uphold your GDPR rights. As part of GDPR you have the right to:</p>
<ol>
<li>Be informed about our collection and use of your Personal Data</li>
<li>Access the Personal Data we hold about you</li>
<li>Have your Personal Data we hold be corrected in cases of inaccuracy or incompleteness</li>
<li>Be forgotten (i.e. ask us to delete or dispose of any Personal Data we have of yours)</li>
<li>Restrict (i.e. prevent) the processing of your Personal Data</li>
<li>Object to us using your Personal Data for a particular purpose or purposes</li>
<li>Data portability (ask for a copy of your Personal Data to re-use with another service or business as long as it is not onerous or commercially prohibitive)</li>
<li>Ensure we use your Personal Data with your consent or for the performance of a contract.</li>
<li>Require your Personal Data to be processed using electronic means and limit parameters associated with automated decision-making and profiling, if any were to be used.</li>
</ol>
<p>Based on these rights, you may ask us not to process your Personal Data for marketing purposes by checking the appropriate box on forms and communications we use to collect your Personal Data.  You can also exercise your right to opt-out, by selecting the opt-out option on our communications, or by contacting our Data Protection Officer on <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a> to be provided with details on your Personal Data we hold, at any time.</p>
<p>In the event Our Site contains a link to or from another website, please note that any such websites are outside our control and are not covered by this Privacy Policy.  Once you leave Our Site via any link, we will not be responsible or liable for any information or Personal Data you disclose.</p>
<p>For information about exercising your rights as outlined above, please contact us using the details provided in the Contacting TalentSage LLC section of this policy. Further information about your rights can also be obtained from the Information Commissioner’s Office in the UK, the European Data Protection Board (EDBP) or your local Citizens Advice Bureau.</p>
<p>If you have any questions or complaints about the way we process your Personal Data, or would like to exercise one of your rights, please contact us using the details provided in the Contacting TalentSage LLC section of this policy and we will do our best to rectify your concerns.</p>
<h1>Correcting Wrong or Incomplete Data</h1>
<p>We care about the integrity of your Personal Data too. Once you have signed into your account successfully (using your user ID and password), you will find basic Account editing tools to allow you to control your Personal Data.</p>
<p>Should you find any incorrect or incomplete Personal Data you are unable to edit on your own, please contact</p>
<ol>
<li><strong>Your leadership development partner</strong> (school, company, coach or organization) if you are using Our Services as part of a Program, Coaching or other Development experience as they provided your Personal Data to us. This will ensure your information is corrected for good. <em>Please copy </em><a href="mailto:Help@TalentSage.com"><em>Help@TalentSage.com</em></a><em>on your email to inform us and help us follow up with your provider.</em></li>
<li><strong>Our Help Desk</strong> directly on <a href="mailto:Help@TalentSage.com"><em>Help@TalentSage.com</em></a>if you provided this information to us directly (e.g. not registered by a leadership development partner), so we may correct or complete your information in cases where you are unabl to do so on your own.</li>
</ol>
<p>In the unlikely event that we have disclosed any of the affected personal data to third-parties, we will inform the relevant third-party of any changes that need to be made to the relevant Personal Data.</p>
<h1>Requesting Access to Your Data</h1>
<p>GDPR gives you the right to access information or data we hold about you. You can access your Personal Data through the following steps:</p>
<ol>
<li><strong>Personal Data provided to us</strong> – Please contact your learning development partner, and/or</li>
<li><strong>Personal Data created or held by us</strong> - Contact us using the details provided in the Contacting TalentSage LLC section of this policy to request details of and/or a copy of your Personal Data held by us (where any such personal data is held). This is known as a “Subject Access Request” (SAR).</li>
</ol>
<p>All SARs should be made in writing and sent to our DPO by one of the following 2 addresses to the “Attention of the TalentSage Data Protection Officer”.</p>
<ol>
<li><strong>Email address:</strong>                   <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></li>
<li><strong>Postal Address:</strong>                  10720 Mississippi Boulevard</li>
</ol>
<p>Coon Rapids, Minnesota 55433-3862</p>
<p>We will respond to your SAR within one (1) calendar month from the date of your request or the date of any additional information we need from you to process your request. We will aim to provide a complete response, including a copy of your Personal Data within that time.</p>
<p>In some complex cases, however, more time may be required. In these cases we commit to providing your information within three (3) calendar months from the date we receive your request or from the date of any additional information we need from you to process your request. You will be kept informed of our progress.</p>
<p>There is not any charge for a SAR, unless your request is deemed ‘unfounded or excessive’ (e.g. repetitive). In this case, we may require a fee to cover our administrative costs in responding and will let you know in writing before conducting and incurring such fee.</p>
<h1>Contacting TalentSage LLC</h1>
<p>Questions, comments or requests regarding this Privacy Policy are welcomed. To contact us about anything related to your personal data and data protection, including Data Subject Access Requests (SAR),</p>
<ol>
<li>Please use the following details</li>
<ol>
<li>Data Protection Officer: Stuart Pembery</li>
<li>Email address: <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></li>
<li>Phone: +1 (628) 236-9135</li>
<li>Postal Address: 10720 Mississippi Boulevard, Coon Rapids, Minnesota 55433-3862</li>
</ol>
<li>Must include <strong>“For the attention of Data Protection Officer”</strong> in the subject of your correspondence</li>
</ol>
<h1>Changes to Our Privacy Policy</h1>
<p>We may change this Privacy Policy from time to time in cases where the law changes or where we change our business in a way that affects how we work with Personal Data. In such cases, any changes will be immediately posted on this page in Our Site. It is your responsibility to check here for any updates. For these reasons, we recommend you check this page regularly to stay current.</p>
<p>The most recent date of any revisions will always be listed in the heading of this page in Our Site for your convenience.</p>
<p>First use of Our Site following any alterations will be deemed as indication of your acceptance of the revised terms of our Privacy Policy.</p>
<p>A significant amount of time may also pass between your use(s) of Our Site. Occasionally, we’ll ask you to reconfirm your understanding of this policy to allow you to change your mind as your needs change. If you do not accept our Privacy Policy terms, you must stop using Our Site immediately.</p>
<h1>Information about TalentSage LLC</h1>
<p>Our Site is owned and operated by TalentSage LLC, a limited liability company incorporated in the state Delaware, United States of America.</p>
<table width="601">
<tbody>
<tr>
<td width="189">
<p>Registered address</p>
</td>
<td width="412">
<p>10720 Mississippi Blvd., Coon Rapids, Minnesota 55433-3862 United States</p>
</td>
</tr>
<tr>
<td width="189">
<p>Commercial registration #</p>
</td>
<td width="412">
<p>4343380</p>
</td>
</tr>
<tr>
<td width="189">
<p>Data Protection Officer</p>
</td>
<td width="412">
<p>Stuart Pembery</p>
</td>
</tr>
<tr>
<td width="189">
<p>Email address</p>
</td>
<td width="412">
<p><a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></p>
</td>
</tr>
</tbody>
</table>
</div>
</template>

<script>
export default {
  name: "ForgotPasswordChoose",
  data: () => ({}),
  methods: {},
  mounted(){},
};
</script>

<style scoped>
.choose-screen {
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 20px;
  padding:20px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
  box-shadow: 0px 2px 10px -4px #000000;
  color: #0E5071
}
.btn {
  background-color: #e67829;
  width: 100%;
  color: #fff;
  fill: #fff;
  font-size: 100%;
  line-height: 1.15;
  font-weight: 500;
  margin: 6px 0;
  border: 0;
  outline: 0;
  border-radius: 2px;
  cursor: pointer;
  letter-spacing: 0.03em;
  padding: 10px 26px;
  box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 20%);
  transition: all 0.2s ease-in-out;
  margin-top: 5px;
  margin-bottom: 10px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #0E5071
}

.description {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #0E5071
}
table {
  border: 1px solid;
}
td {
  border: 1px solid;
}
td {
  padding: 10px;
}
p{
  color:#0E5071
}
h1{
  color: #0E5071
}
ol>li{
  color: #0E5071  
}
</style>
