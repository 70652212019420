<script setup>
import Home from "./HomeComponent.vue";
import api from "../api/api.js";
import store from "../store/store.js"
import CryptoJS from 'crypto-js'
import ButtonSubmit from "./ButtonSubmit.vue";
import { flashMessage } from "../functions.js";
import Bowser from "bowser";
</script>

<template>
  <div v-if="isLoggedIn">
    <div>
      <Home />
    </div>
  </div>
  <div v-else>
    <div class="login-form">
      <div>
        <form @submit.prevent="handleLogin()" method="post">
          <h3>Welcome! <br>Sign in here to grow your leadership skills.</h3>
          <div class="form-group">
            <input
              type="text"
              name="username"
              v-model="username"
              class="form-control"
              placeholder="Email Address "
              required="required"
              id="emailInput"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              name="password"
              v-model="password"
              class="form-control"
              placeholder="Password  (8+ characters)"
              required="required"
              id="passwordInput"
            />
            <span @click="showPassword()">
              <i class="fa fa-eye"
              aria-hidden="true"
              id="eye"
              ></i>
            </span>
          </div>
          <div class="form-group button-div">
            <ButtonSubmit label="Sign In" style="border: 1px; box-shadow: 2px 4px #ccc;"/>
          </div>
        </form>
        <div class="forgot-password"><p><router-link to="/forgot_password_screen">Forgot password?</router-link></p></div>
        <div class="btm-msg"><h3>Click "Sign In" to agree with TalentSage's <a href="#">Terms of Service <br></a>and acknowledge that TalentSage's <br><a href="https://ts.talentsage.com/#/privacy_page">Privacy Policy</a> applies to you.</h3></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  component: [Home, ButtonSubmit],
  name: "LoginComponent",
  data: () => ({
    isLoggedIn: false,
    logOutButton: true,        
    username: "",              
    password: "",             
    loginResults: [],
    routeAuthResults: [],
    Roles : {},  
    currentRole : [],     
    platform: '',
    browser: '',
    deviceCore: '',
    operatingSystem: ''    
  }),
  methods: {
    async handleLogin() {
      await api
        .post('auths', {
          email: this.username.toLowerCase().trim(),
          password: this.password,
        })
        .then((res) => {
          this.loginResults = res.data;
          if (this.loginResults.length <= 0) {
            flashMessage(this.$flashMessage, '#F47820', '#ffffff', "Wrong username or password!")
            this.userLog(0)
            return null;
          } 
          else if (this.loginResults[0].roles == "" || this.loginResults[0].roles == null)
          {
            flashMessage(this.$flashMessage, '#F47820', '#ffffff', "Invalid user")
            this.userLog(0)
            return null;
          }
        api.put('/auths/lastlogin', {
          email: this.username.toLowerCase().trim(),
        })
          this.Roles = this.loginResults[0].roles
          api.get('roles').then((result) => {
            var rolesData = result.data
            var newValue = this.Roles.split(", ")
            var newRoleName = newValue.map((r) => rolesData.find((f) => f.role_id == r).role_name)
            this.currentRole = newRoleName
            this.$emit("passLoginUp", this.loginResults[0]);
            this.$emit("passBrandUp", this.loginResults[0].brandData);
            this.isLoggedIn = true;
            this.userLog(1)
            this.$router.push("/");
            this.$emit("authenticate", this.isLoggedIn);
            store.commit('addAuthString', this.decrypt(this.loginResults[0].auth_string, this.loginResults[0].seed))
        
            this.$flashMessage.show({
              html: `<div style="background-color: ${this.loginResults[0].brandData.accent_color1 ? this.loginResults[0].brandData.accent_color1 : '#F47820'}; width:100%;">
                <p style="margin-bottom:0; margin-left:1rem; color: #fff;">Welcome ${this.loginResults[0].first_name} !</p>
                <p style="margin-top:0; margin-left:1rem; color: #fff;">Role(s): ${this.currentRole.join(", ")}</p>
              </div>`,
              clickable: true,
            });
        })
        .catch((e) => {
          flashMessage(this.$flashMessage, '#F47820', '#ffffff', e.message)
          return null;
        });
      });
      console.log(this.isLoggedIn)
    },
    showPassword() {
      var x = document.getElementById("passwordInput");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    decrypt (src, passphrase) {
      const bytes = CryptoJS.AES.decrypt(src, passphrase)
      return bytes.toString(CryptoJS.enc.Utf8)
    },
    async userLog(loggedIn){
      try {
        const res = await api.post("insertUserLog", {
          email: this.username,
          is_logged_in: loggedIn,
          platform: this.platform,
          operating_system: this.operatingSystem,
          browser: this.browser,
          logical_processors: this.deviceCore
        })
        if(res){
          console.log(res)
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.platform = browser.getPlatformType()
    this.browser = `${browser.getBrowserName()} V.${browser.getBrowserVersion()}`
    this.operatingSystem = `${browser.getOSName()} ${browser.getOSVersion()}`
    
    if(navigator.hardwareConcurrency){
        this.deviceCore = navigator.hardwareConcurrency + " cores"
    }else{
        this.deviceCore = "N/A"
    }

    console.log(this.platform)
    console.log(this.browser)
    console.log(this.operatingSystem)
    
  }
};
</script>

<style scoped>
.login-form {
  background: white;
  border-radius: 20px;
  padding:20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 600px;
  box-shadow: 0px 2px 10px -4px #000000;
      /* If you want to implement it in very old browser-versions */
-webkit-user-select: none; /* Chrome/Safari */ 
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+ */

/* The rule below is not implemented in browsers yet */
-o-user-select: none;

/* The rule below is implemented in most browsers by now */
user-select: none;
}
.btn {
  width: 30%;
  font-size: 100%;
  line-height: 1.15;
  font-weight: 500;
  margin: 6px 0;
  cursor: pointer;
  letter-spacing: 0.03em;
  padding: 10px 26px;
  box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 20%);
  transition: all 0.2s ease-in-out;
}
input[type="checkbox"] {
  transform: scale(2);
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.form-control {
  height: 56px;
  font-size: 1rem;
}

.form-group input {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding: 16.5px 14px;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #0E5071;
}
.form-group {
  position: relative;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 10px;
}

label {
  font-size: 14px;
  padding-left: 16px;
}
.btm-msg {
  text-align: center;
  margin-top: 50px;
    /* If you want to implement it in very old browser-versions */
-webkit-user-select: none; /* Chrome/Safari */ 
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+ */

/* The rule below is not implemented in browsers yet */
-o-user-select: none;

/* The rule below is implemented in most browsers by now */
user-select: none;
}
span {
  position: absolute;
  top: 37%;
  left: 93%;
  transform: translate(0, -50%);
  cursor: pointer;
  right: 15px;
  color: #F47820;
  font-size: 25px;
}
span:hover {
  color: #f47820bd;
}
span:active {
  color: #ff6a00;
}
.forgot-password{
  /* If you want to implement it in very old browser-versions */
-webkit-user-select: none; /* Chrome/Safari */ 
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+ */

/* The rule below is not implemented in browsers yet */
-o-user-select: none;

/* The rule below is implemented in most browsers by now */
user-select: none;
}
.button-div{
  display: flex;
  justify-content: center;
}
@media (max-width: 480px) {
.fa-eye {
margin-left: -15px;
cursor: pointer;
position: relative;
z-index: 2;          
}
}
</style>
